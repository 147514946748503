<template>
  <div v-if="company && company.role && !loader">
    <b-card
        v-if="task.visible == 1 && prevTask == null"
        class="card-congratulation-medal card-home-border"
        @click="openTask(task.task_id, task.status)"
        :class="task.status == 0 && index != 0 ? 'disabled-card' : ''"
    >
  
      <div class="col-md-12 mb-0">
        <h2 class="mb-75 p-0">
          <b-link> {{ $t(`task_${task.task_id -1}_${company.role}_title`) }}</b-link>
        </h2>
        <b-card-text class="font-small-8 mt-1">
          {{ $t(`task_${task.task_id -1}_${company.role}_description`) }}</b-card-text
        >
      </div>

      <div class="congratulation-medal mt-2 mb-1">
        <h4 v-if="task.status == 1" class="text-success">
          <span class="mr-50 bullet bullet-success bullet-sm" />
        </h4>
        <h4 v-if="task.status == 0">
            <span class="mr-50 bullet bullet-info bullet-sm" />
        </h4>
        <h4 v-if="task.status == 2 || task.status == 3" class="text-primary">
          <span class="mr-50 bullet bullet-primary bullet-sm" />
        </h4>
        <!-- <h4 v-if="task.status == 3" class="text-warning">
          <span class="mr-50 bullet bullet-warning bullet-sm" />
          {{ $t("partially_approved") }}
        </h4> -->
      </div>
      <div class=" mt-0 mb-1">
        <h4 v-if="task.status == 1" class="text-success text-font-size">
          
          <span class="text-success text-font-size ml-1">
          {{ $t("completed") }}
          </span>
        </h4>
        <h4 v-if="task.status == 0">
          <span class="text-info text-font-size ml-1">
            {{ $t("Open") }}
          </span>
        </h4>
        <h4 v-if="task.status == 2 || task.status == 3" class="text-primary text-font-size">
          <span class="text-primary text-font-size ml-1">
          {{ $t("in_review") }}</span>
        </h4>
        <!-- <h4 v-if="task.status == 3" class="text-warning">
          <span class="mr-50 bullet bullet-warning bullet-sm" />
          {{ $t("partially_approved") }}
        </h4> -->
      </div>
    </b-card>
    <b-card
        v-if="task.visible == 1 && prevTask != null"
        class="card-congratulation-medal card-home-border"
        @click="openTask(task.task_id, task.status)"
        :class="(task.status == 0 &&  prevTask.status == 0) && index != 0 ? 'disabled-card' : ''"
    >
  
      <div class="col-md-12 mb-0">
        <h2 class="mb-75 p-0">
          <b-link> {{ $t(`task_${task.task_id -1}_${company.role}_title`) }}</b-link>
        </h2>
        <b-card-text class="font-small-8 mt-1">
          {{ $t(`task_${task.task_id -1}_${company.role}_description`) }}</b-card-text
        >
      </div>

      <div class="congratulation-medal mt-2 mb-1">
        <h4 v-if="task.status == 1" class="text-success">
          <span class="mr-50 bullet bullet-success bullet-sm" />
          
        </h4>
        <h4 v-if="task.status == 0">
          <span v-if="prevTask==null || (prevTask.status == 1 || prevTask.status == 2 || prevTask.status == 3)" class="text-info">
            <span class="mr-50 bullet bullet-info bullet-sm" />
        
          </span>
          <span v-if="prevTask!=null && prevTask.status == 0" class="text-warning">
            <span class="mr-50 bullet bullet-warning bullet-sm" />
           
          </span>
        </h4>
        <h4 v-if="task.status == 2 || task.status == 3" class="text-primary">
          <span class="mr-50 bullet bullet-primary bullet-sm" />
          
        </h4>
        <!-- <h4 v-if="task.status == 3" class="text-warning">
          <span class="mr-50 bullet bullet-warning bullet-sm" />
          {{ $t("partially_approved") }}
        </h4> -->
      </div>
      <div class=" mt-0 mb-1">
        <h4 v-if="task.status == 1" class="text-success text-font-size">
          <span class="text-success text-font-size ml-1">
          {{ $t("completed") }}
          </span>
        </h4>
        <h4 v-if="task.status == 0">
          <span v-if="prevTask.status != 0" class="text-info text-font-size ml-1">
            {{ $t("Open") }}
          </span>
          <span v-if="(prevTask.status == 0)" class="text-warning text-font-size ml-1">
            {{ $t("complete_previous_task") }}
          </span>
        </h4>
        <h4 v-if="task.status == 2 || task.status == 3" class="text-primary text-font-size ml-1">
          {{ $t("in_review") }}
        </h4>
        <!-- <h4 v-if="task.status == 3" class="text-warning">
          <span class="mr-50 bullet bullet-warning bullet-sm" />
          {{ $t("partially_approved") }}
        </h4> -->
      </div>
    </b-card>
  </div>
  <div v-else></div>

</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
export default {
  components: {
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disabledCard: 0,
      spiner: false,
      company: null,
      loader: true,
    };
  },

  props: ["task", "prevTask", "index", "data"],
  name: "CookpitTask",
  created() {
    this.loader = true;
    this.company = this.data;

    if (this.company == null) {
      this.$http
          .post(`/company/show/${this.$store.state.auth.user.company_id}`)
          .then((res) => {
            this.$store.commit("app/SET_COMPANY_INFO", res.data);
            this.company = res.data.value;
            this.type = this.company.role;
          });
      this.loader = false;
    } else {
      this.type = this.company.role;
      this.loader = false;
    }
  },
  methods: {
    openTask(task, status) {
      if (this.company && this.company.role && this.company.role == "buyer") {
        this.buyer(task, status);
      }
     if (status == 0 || status == 2) {
        if (this.company && this.company.role && (this.company.role == "seller" || this.company.role == null)) {
          this.seller(task, status);
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("task_already_completed"),
            icon: "SuccessIcon",
            variant: "success",
          },
        });
      }
    },
    buyer(task, status) {
      if (task == 1) {
        this.$router.push("/cookpit/task/add-buyer");
        if (status == 0) {
          this.$store.commit("app/FIRST_TASK_STATUS", true);
        } else {
          this.$store.commit("app/FIRST_TASK_STATUS", false);
        }
      } else if (task == 2 && this.prevTask.status > 0) {
        this.$router.push("/cookpit/task/confirm-beneficial-owner");
      } else if (task == 3 && this.prevTask.status > 0) {
        this.$router.push("/cookpit/task/upload-identification-documents");
      } else if (task == 4 && this.prevTask.status > 0) {
        this.$router.push("/cookpit/task/transfer-symbolic-amount");
      } else if (task == 5 && this.prevTask.status > 0) {
        this.$router.push("/cookpit/task/activate-account");
      }
    },
    seller(task, status) {
      if (task == 1) {
        if (status == 2) {
          this.$router.push("/settings");
        } else if (status == 0) {
          this.$router.push("/cookpit/task/add-company");
        }
      } else if (task == 2 && this.prevTask.status > 0) {
        this.$http.post("/invoice/list").then((res) => {
          if (typeof res.data.value.data.data != "undefined") {
            if (res.data.value.data.data.length > 2 && status == 1) {
              this.$router.push("/invoices");
            } else {
              this.$router.push("/cookpit/task/upload-invoices");
            }
          }
        });
      } else if (task == 3 && this.prevTask.status > 0) {
        if (this.$store.state.app.hasDocs) {
          this.$router.push("/documents");
        } else {
          this.$router.push("/cookpit/task/upload-docs");
        }
      } else if (task == 4 && (this.prevTask.status > 0 || (this.prevTask.visible == 0 && this.prevTask.status==0)) && this.company.name != null ){
        this.$router.push("/cookpit/task/fill-online-form");
      }else if(task == 5 && this.prevTask.status > 0) {
        localStorage.setItem("task_status", JSON.stringify(status));
        this.$router.push("/cookpit/task/complete-identification");
      }
    },
  },
};
</script>

<style></style>
