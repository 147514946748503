<template>
    <div>
        <b-modal
            v-model="withdrawModal"
            style="padding-top: 10vh;"
            scrollable
            no-close-on-backdrop
            size="md"
            hide-header
        >
            <template #modal-footer="{}">
                <img v-if="wthdrawLoader" height="40" width="40" src="/new-loader.svg"/>
                <b-button size="sm" variant="outline-primary" @click="cancelWithdraw()">
                    {{ $t("back") }}
                </b-button>
                <b-button :disabled="wthdrawLoader" id="disabled_button" size="sm" variant="primary" @click="submitWithdraw()">
                    {{ $t("submit") }}
                </b-button>
            </template>

            <validation-observer ref="withdrawValidation">
                <b-form @submit.prevent="submitWithdraw">
                    <div>
                        <h3>{{ $t("saldo_balance") }}</h3>
                        <h5 class="my-1">
                            <span v-if="withdrawData.balance.openBalance">
                                {{ amountFormat(withdrawData.balance.openBalance, withdraw_currency) }}
                            </span>
                            <span v-else>
                                {{ withdraw_currency }} 00'000.00
                            </span>
                        </h5>

                        <b-dropdown id="dropdown-custom-options" class="mb-2" style="width: 130px;" variant="outline-dark">

                            <template #button-content>
                                <span v-if="withdraw_currency">{{ withdraw_currency }}</span>
                                <span v-else id="dropdown_text">{{ $t('select_currency') }}</span>
                            </template>


                            <b-dropdown-item
                                v-for="option in currencies"
                                :key="option.id"
                                @click="changeCurrency(option.name, option.id, option.type, option.code)"
                            >

                                {{ option.name }}
                            </b-dropdown-item>

                        </b-dropdown>

                        <b-form-group
                            id="withdraw_amount"
                            class="font-weight-bolder"
                            :label="$t('specify_withdraw')"
                            label-for="withdraw_amount"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('withdraw_amount')"
                                rules="required"
                            >
                                <b-form-input
                                    id="amount-input"
                                    :placeholder="placeholder"
                                    v-model.trim="withdrawData.withdraw_amount"
                                    type="number"
                                    :min="0"
                                    step="0.01"
                                    required
                                    autocomplete="off"
                                ></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-col md="12" class="pt-0">
                            <b-form-radio
                                :disabled="disableInput"
                                class="mb-1"
                                value="own"
                                name="check-button"
                                @change="ChangeAccount('own')"
                            >
                                <span>{{ $t("own") }}</span>
                            </b-form-radio>

                            <b-form-radio
                                :disabled="disableInput"
                                value="external"
                                name="check-button"
                                @change="ChangeAccount('external')"
                            >
                                <span>{{ $t("external") }}</span>
                            </b-form-radio>
                        </b-col>

                        <hr>
                        <template v-if="!send_verification_code">
                            <div class="mb-1 font-weight-bold"> {{ $t("enter_bank_info") }}</div>

                            <div v-if="choseAccount">
                                <div v-if="withdraw_currency_type != 'crypto'" class="d-flex justify-content-between">
                                    <span>{{ $t("account_owner") }}</span>
                                    <b-form-group
                                        id="account_owner"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('account_owner')"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model.trim="withdrawData.account_owner"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <span v-if="withdraw_currency_code == 'USDT'">Chains</span>

                                        <div class="d-flex flex-column">
                                            <b-form-radio-group
                                                v-if="withdraw_currency_code == 'USDT'"
                                                class="my-1"
                                                id="radio-options"
                                                v-model="selectedOption"
                                                :options="options"
                                            ></b-form-radio-group>

                                        </div>

                                </div>
                                <div class="d-flex justify-content-between">

                                    <span v-if="withdraw_currency_type != 'crypto'">{{ $t("iban") }}</span>
                                    <span v-else>Wallet</span>
                                        <div class="d-flex flex-column">
                                            <b-form-group id="iban" class="font-weight-bolder">
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="$t('iban')"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        v-model.trim="withdrawData.iban"
                                                        required
                                                    ></b-form-input>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                </div>

                                <div v-if="withdraw_currency_type != 'crypto'" class="d-flex justify-content-between">
                                    <span>{{ $t("bank_name") }}</span>
                                    <b-form-group
                                        id="bank_name"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('bank_name')"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model.trim="withdrawData.bank_name"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>

                                <div v-if="withdraw_currency_type != 'crypto'" class="d-flex justify-content-between">
                                    <span>{{ $t("bic") }}</span>
                                    <b-form-group
                                        id="bic"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('bic')"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model.trim="withdrawData.bic"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>

                                <template v-if="choseAccount === 'external'">
                                    <div v-if="withdraw_currency_type != 'crypto'" class="d-flex justify-content-between">
                                        <span>{{ $t("account_owners_address") }}</span>
                                        <b-form-group
                                            id="account_owners_address"
                                            class="font-weight-bolder"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="$t('account_owners_address')"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    v-model.trim="withdrawData.accountOwnersAddress"
                                                    required
                                                ></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </template>

                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("purpose_the_payment") }}</span>
                                    <b-form-group
                                        id="purpose_the_payment"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('purpose_the_payment')"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model.trim="withdrawData.purposePayment"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("reference_number") }}</span>
                                    <b-form-group
                                        id="reference_number"
                                        class="font-weight-bolder"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="$t('reference_number')"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model.trim="withdrawData.reference_number"
                                                :disabled="referenceNumberDisable"
                                                required
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>

                                <b-form-file
                                    @change="uploadPdfFile($event)"
                                    :placeholder="$t('upload_a_file_or_drag_files_here')"
                                    drop-placeholder="Drop file here..."
                                    id="upload_pdf"
                                ></b-form-file>

                                <div style="font-size: 13px; font-weight: bold" class="my-2">
                                    <p>{{ $t("own_business_bank_account_modal_text_1") }}</p>
                                    <p v-if="choseAccount == 'own'">{{ $t("own_business_bank_account_modal_text_2")
                                        }}</p>
                                    <p v-else>{{ $t("external_business_bank_account_modal_text") }}</p>
                                    <p>
                                        {{ $t("own_business_bank_account_modal_text_3") }}
                                        <span v-if="withdraw_currency_type != 'crypto'">{{ withdraw_currency }}</span>
                                        <span v-else>CHF</span>
                                        {{ $t("own_business_bank_account_modal_text_4") }}
                                    </p>
                                    <p>{{ $t("own_business_bank_account_modal_text_5") }}</p>
                                </div>

                                <b-form-group class="mt-1">
                                    <b-form-checkbox class="mb-1" v-model="isConfirmed">{{ $t('accept') }}
                                    </b-form-checkbox>
                                    <p class="mb-2" style="color: red; font-size: 11px;" v-if="isConfirmedErrorMessage">
                                        {{isConfirmedErrorMessage}}</p>
                                </b-form-group>
                            </div>
                        </template>
                        <template v-else>
                            <b-form-group :label="$t('code')" label-for="code">
                                <validation-provider #default="{ errors }" name="code">
                                    <b-form-input id="code" placeholder="****" v-model.trim="verification_code" rules="reaquired"/>
                                    <small style="color: red;">{{errorMessage}}</small>
                                </validation-provider>
                            </b-form-group>
                        </template>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
<script>
  import i18n from "@/libs/i18n";
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import {BImg} from "bootstrap-vue";
  import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

  export default {
    props: {
      withdrawModal: Boolean,
      wthdrawLoader: Boolean,
      withdrawData: Object,
      oldWithdrawData: Object,
    },
    components: {
      ValidationProvider,
      ValidationObserver,
      i18n,
      BImg
    },
    data() {
      return {

        company: this.$store.state.auth.user.company,
        disableInput: false,
        referenceNumberDisable: true,
        choseAccount: null,
        AmountSelectFields: null,
        // selectedCurrency: null,
        isConfirmed: false,
        // SellerAmountSelectFields: [
        //     { value: 'CHF', text: 'CHF', flag: require('@/assets/images/currency/chf.png') },
        //     { value: 'EUR', text: 'EUR', flag: require('@/assets/images/currency/eur.png') },
        //     { value: 'USD', text: 'USD', flag: require('@/assets/images/currency/usd.png') },
        //     { value: 'GBP', text: 'GBP', flag: require('@/assets/images/currency/gbp.png') },
        // ],
        //
        // BuyerAmountSelectFields: [
        //     { value: 'CHF', text: 'CHF', flag: require('@/assets/images/currency/chf.png') },
        //     { value: 'EUR', text: 'EUR', flag: require('@/assets/images/currency/eur.png') },
        //     { value: 'USD', text: 'USD', flag: require('@/assets/images/currency/usd.png') },
        //     { value: 'GBP', text: 'GBP', flag: require('@/assets/images/currency/gbp.png') },
        //     { value: 'BTC', text: 'BTC', flag: require('@/assets/images/currency/btc.png') },
        //     { value: 'ETH', text: 'ETH', flag: require('@/assets/images/currency/eth.png') },
        //     { value: 'USDT', text: 'USDT', flag: require('@/assets/images/currency/usdt.png') },
        // ],

        dataToSubmit: this.withdrawData,
        loader: this.wthdrawLoader,
        currencies: null,
        withdraw_currency: "CHF",
        withdraw_currency_id: 1,
        withdraw_currency_code: 'chf',
        isConfirmedErrorMessage: null,
        withdraw_currency_type: null,
        verification_code: null,
        send_verification_code: false,
        verification_code_bool: false,
        errorMessage: null,
        options: [
          { text: 'ERC20', value: 'erc' },
          { text: 'TRC20', value: 'trc' },
        ],
        selectedOption: 'erc',
        placeholder: null
      }
    },
    created() {
      this.getCurrencies();
      this.changeAmountFields()
      this.placeholder = this.amountFormat(1234.56, "CHF", 'type')
    },
    methods: {
      amountFormat(amount, currency, type) {
        return CurrencyAmountFormat(amount, currency, type)
      },
      uploadPdfFile(event) {
        const file = event.target.files[0];
        if (file) {
          this.withdrawData.pdfPath = file;
        }
      },

      getCurrencies() {
        this.$http.post(`/currency/withdraw-currency/${this.company.id}`).then((response) => {
          if (response.data.status == 200) {
            this.currencies = response.data.value;
            console.log(this.currencies, 1111);
          }
        });
      },

      changeCurrency(value, id, type, code) {

        this.withdraw_currency_type = type;

        if (value) {
          this.withdraw_currency = value;
        }

        if (id) {
          this.withdraw_currency_id = id
        }

        if (type) {
          this.withdraw_currency_type = type
        }

        if (code) {
          this.withdraw_currency_code = code
        }

        this.$emit('getBalance', id);

        this.placeholder = this.amountFormat(1234.56, code, 'type')

      },

      ChangeAccount(type) {
        this.choseAccount = type;
        if (type == 'external') {
          this.disableInput = true;
          // this.referenceNumberDisable = false;
          this.withdrawData.account_owner = null;
          this.withdrawData.iban = null;
          this.withdrawData.bank_name = null;
          this.withdrawData.bic = null;
          this.withdrawData.accountOwnersAddress = null;
          this.withdrawData.purposePayment = null;

          // this.withdrawData.reference_number = null;
        }
        // else {
        //   console.log("esssss", this.dataToSubmit);
        //   this.withdrawData.withdraw_amount = this.oldWithdrawData.withdraw_amount;
        //   this.withdrawData.account_owner = this.oldWithdrawData.account_owner;
        //   this.withdrawData.iban = this.oldWithdrawData.iban;
        //   this.withdrawData.bank_name = this.oldWithdrawData.bank_name;
        //   this.withdrawData.bic = this.oldWithdrawData.bic;
        //   this.withdrawData.reference_number = this.oldWithdrawData.reference_number;
        // }

      },

      changeAmountFields() {
        this.AmountSelectFields = this.$store.state.auth.user.crole == 'seller' ? this.SellerAmountSelectFields : this.BuyerAmountSelectFields
      },
      cancelWithdraw() {
        this.$emit('cancelWithdraw')
      },

      async submitWithdraw() {

        if (!this.isConfirmed) {
          this.isConfirmedErrorMessage = "You must confirm that you agree with the rules"
          return
        }

        this.dataToSubmit = this.withdrawData;

        this.$refs.withdrawValidation.validate().then(async (valid) => {
          if (valid) {
            // document.getElementById('disabled_button').disabled = true
            const formData = new FormData();

            formData.append('withdraw_amount', this.dataToSubmit.withdraw_amount);
            formData.append('reference_number', this.dataToSubmit.reference_number);
            formData.append('currency_id', this.withdraw_currency_id);
            formData.append('account_type', this.choseAccount);
            formData.append('purpose_payment', this.dataToSubmit.purposePayment);
            formData.append('file', this.dataToSubmit.pdfPath);
            formData.append('iban', this.dataToSubmit.iban);

            if (this.withdraw_currency_type != 'crypto') {
              formData.append('account_owner', this.dataToSubmit.account_owner);
              formData.append('bank_name', this.dataToSubmit.bank_name);
              formData.append('bic', this.dataToSubmit.bic);
              formData.append('account_address', this.dataToSubmit.accountOwnersAddress);
            }

            if(this.withdraw_currency_type == 'crypto' && this.withdraw_currency_code == 'USDT') {
              formData.append('selected_option', this.selectedOption);
            }

            this.loader = true;

            if (this.withdraw_currency_type == 'crypto' && !this.send_verification_code) {

              this.$http.post(`/currency/send-verification-code`).then((response) => {
                if (response.data.status == 200) {
                  this.currencies = response.data.value;
                  this.send_verification_code = true
                  return
                }
              });
            }

            if (this.send_verification_code && !this.verification_code_bool) {
              //   this.$http.post(`/currency/enter-verification-code`, {code: this.verification_code}).then((response) => {
              //   if (response.data.status == 200 && response.data.message == "true") {
              //     this.verification_code_bool = true;
              //   } else if (response.data.status == 200 && response.data.message == "false") {
              //     this.errorMessage = 'Your verification code is incorrect'
              //     return
              //   }
              // });
              try {
                const response = await this.$http.post(`/currency/enter-verification-code`, {code: this.verification_code});
                if (response.data.status === 200) {
                  if (response.data.message === "true") {
                    this.verification_code_bool = true;
                  } else if (response.data.message === "false") {
                    this.errorMessage = 'Your verification code is incorrect';
                  }
                }
              } catch (error) {
                // Обработка ошибок, если запрос не удался
                console.error('An error occurred:', error);
                this.errorMessage = 'An error occurred while sending the verification code';
              }
            }

            if (
                this.withdraw_currency_type != 'crypto' ||
                (this.withdraw_currency_type == 'crypto' && this.verification_code_bool)
            ) {
              this.$http
                  .post(`/company/withdraw`, formData)
                  .catch((error) => {
                    if (error) {
                      this.loader = false;
                    }
                  })
                  .then((res) => {
                    if (res) {
                      if (res.data.status == 200) {
                        this.showSuccessMessage(i18n.t("success"));
                        this.loader = false;
                        this.$emit('getBalance', this.withdraw_currency_id);
                        location.reload()

                      } else {
                        this.$swal({
                          position: "center",
                          icon: "error",
                          title: res.data.message,
                          showConfirmButton: false,
                          timer: 1500,
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: false,
                        });
                      }
                      this.loader = false;
                      this.dataToSubmit.withdraw_amount = null;
                      this.$emit('cancelWithdraw')
                    }
                  });
            }
          }
        });
      },

      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

      },
    }
  }

</script>