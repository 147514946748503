<template>
  <b-card class="mt-2">
    <div v-if="options && company!=null">
      <div class="d-flex align-items-center">
        <div>
          <h4 class="mb-0" style="color: #1B4332 ;">
            {{ $t(options.title) }}
          </h4>
          <span>{{ $t(options.subtitle) }}</span>
        </div>
      </div>

      <!-- collapse -->
      <app-collapse id="faq-payment-qna" v-if="company && company.role && (company.role == 'seller' || company.role == null)" accordion type="margin" class="mt-2">
        <app-collapse-item
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important"
          v-for="(data, index) in filteredQandA"
          class="mt-1 mb-1 open faq-no-shadow"
          :key="index"
          :title="$t(data.question)"
        >
          {{$t(data.ans)}}
        </app-collapse-item>
      </app-collapse>
      <app-collapse id="faq-payment-qna-buyer" v-if="company.role &&  company.role == 'buyer'" accordion type="margin" class="mt-2">
        <app-collapse-item
          style="box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0 !important"
          v-for="(data, index) in filteredBuyerQandA"
          class="mt-1 mb-1 open faq-no-shadow"
          :key="index"
          :title="$t(data.question)"
        >
          {{$t(data.ans)}}
        </app-collapse-item>
      </app-collapse>
    </div>
  </b-card>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  props:['data'],
  components: {
    AppCollapseItem,
    AppCollapse,
  },
  data() {
    return {
     company:null,
      options: {
        title: "faq_title",
        subtitle:"lets_answer_some_questions",
        icon: "creditCardIcon",
        qandA: [
          {
            t:"lets_answer_some_questions",
            question: "faq_1_seller_q",
            ans:"faq_1_seller_a",
          },
          {
            t:"faq_title",
            question: "faq_2_seller_q",
            ans: "faq_2_seller_a",
          },
          {
            t:"faq_title",
            question: "faq_3_seller_q",
            ans: "faq_3_seller_a",
          },
          {
            t:"faq_title",
            question: "faq_4_seller_q",
            ans: "faq_4_seller_a",
          },
          {
            question: "faq_5_seller_q",
            ans: "faq_5_seller_a",
          },
          {
            question: "faq_6_seller_q",
            ans: "faq_6_seller_a",
          },
          {
            question: "faq_7_seller_q",
            ans: "faq_7_seller_a",
          },
          {
            question: "faq_8_seller_q",
            ans: "faq_8_seller_a",
          },
          {
            question: "faq_9_seller_q",
            ans: "faq_9_seller_a",
          },
          {
            question: "faq_10_seller_q",
            ans: "faq_10_seller_a",
          },
          
        ],
        qandABuyer: [
          {
            question: "faq_1_buyer_q",
            ans: "faq_1_buyer_a",
          },
          {
            question: "faq_2_buyer_q",
            ans:"faq_2_buyer_a",
          },
          {
            question:"faq_3_buyer_q",
            ans: "faq_3_buyer_a",
          },
          {
            question: "faq_4_buyer_q",
            ans: "faq_4_buyer_a",
          },
          {
            question: "faq_5_buyer_q",
            ans: "faq_5_buyer_a",
          },
          {
            question: "faq_6_buyer_q",
            ans:"faq_6_buyer_a",
          },
          {
            question:"faq_7_buyer_q",
            ans: "faq_7_buyer_a",
          },
          {
            question: "faq_8_buyer_q",
            ans: "faq_8_buyer_a",
          },
          {
            question: "faq_9_buyer_q",
            ans: "faq_9_buyer_a",
          },
          {
            question: "faq_10_buyer_q",
            ans: "faq_10_buyer_a",
          },
        ],
      },
    };
  },
  computed: {
    filteredQandA() {
      return this.options.qandA.filter((data) => {
        return this.$t(data.question) !== '-' && this.$t(data.ans) !== '-';
      });   
     },
     filteredBuyerQandA() {
      return this.options.qandABuyer.filter((data) => {
        return this.$t(data.question) !== '-' && this.$t(data.ans) !== '-';
      });
     },
  },
  created() {

    //this.company = this.$store.state.app.company.value
    if(this.company==null){
      this.$http
          .post(`/company/show/${this.$store.state.auth.user.company_id}`)
          .then((res) => {
            this.$store.commit("app/SET_COMPANY_INFO", res.data);
            this.company = res.data.value;
          });
    }
  },
};
</script>
<style>
.faq-no-shadow .card-header,
.faq-no-shadow {
  box-shadow: none !important;
}
</style>
