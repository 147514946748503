var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.send_verification_code)?[_c('b-form-group',{attrs:{"label":_vm.$t('original_currency') + ':',"id":"original_currency","label-for":"original-currency"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"original_currency",attrs:{"id":"original-currency"}},[_c('b-form-select',{attrs:{"options":_vm.currencyOptions},on:{"change":function($event){return _vm.change(
                                _vm.exchangeCurrenciesData.original_currency,
                                _vm.exchangeCurrenciesData.exchange_currency,
                                _vm.exchangeCurrenciesData.original_amount,
                                'change1'
                            )}},model:{value:(_vm.exchangeCurrenciesData.original_currency),callback:function ($$v) {_vm.$set(_vm.exchangeCurrenciesData, "original_currency", $$v)},expression:"exchangeCurrenciesData.original_currency"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":_vm.placeholder,"id":"original-currency-input","step":"0.01","type":"number"},on:{"input":function($event){return _vm.change(
                                _vm.exchangeCurrenciesData.original_currency,
                                _vm.exchangeCurrenciesData.exchange_currency,
                                _vm.exchangeCurrenciesData.original_amount,
                                'change1'
                            )}},model:{value:(_vm.exchangeCurrenciesData.original_amount),callback:function ($$v) {_vm.$set(_vm.exchangeCurrenciesData, "original_amount", $$v)},expression:"exchangeCurrenciesData.original_amount"}})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('exchange_currency') + ':',"id":"exchange_currency","label-for":"exchange-currency"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"exchange_currency",attrs:{"id":"exchange-currency"}},[_c('b-form-select',{attrs:{"options":_vm.currencyOptions},on:{"change":function($event){return _vm.change(
                                _vm.exchangeCurrenciesData.original_currency,
                                _vm.exchangeCurrenciesData.exchange_currency,
                                _vm.exchangeCurrenciesData.original_amount,
                                'change1'
                            )}},model:{value:(_vm.exchangeCurrenciesData.exchange_currency),callback:function ($$v) {_vm.$set(_vm.exchangeCurrenciesData, "exchange_currency", $$v)},expression:"exchangeCurrenciesData.exchange_currency"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":_vm.placeholder,"id":"exchange-currency-input","step":"0.01","type":"number"},on:{"input":function($event){return _vm.change(
                                _vm.exchangeCurrenciesData.exchange_currency,
                                _vm.exchangeCurrenciesData.original_currency,
                                _vm.exchangeCurrenciesData.exchange_amount,
                                'change2'
                            )}},model:{value:(_vm.exchangeCurrenciesData.exchange_amount),callback:function ($$v) {_vm.$set(_vm.exchangeCurrenciesData, "exchange_amount", $$v)},expression:"exchangeCurrenciesData.exchange_amount"}})],1)],1)]:[_c('b-form-group',{attrs:{"label":_vm.$t('code'),"label-for":"code"}},[_c('validation-provider',{attrs:{"name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","placeholder":"****","rules":"reaquired"},model:{value:(_vm.verification_code),callback:function ($$v) {_vm.verification_code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"verification_code"}}),_c('small',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.errorMessage))])]}}])})],1)],_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t('exchange_modal_text1')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t('exchange_modal_text2')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('exchange_modal_point1_heading')))]),_vm._v(_vm._s(_vm.$t('exchange_modal_point1_text')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('exchange_modal_point2_heading')))]),_vm._v(_vm._s(_vm.$t('exchange_modal_point2_text')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('exchange_modal_point3_heading')))]),_vm._v(_vm._s(_vm.$t('exchange_modal_point3_text')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('exchange_modal_point4_heading')))]),_vm._v(_vm._s(_vm.$t('exchange_modal_point4_text')))]),_c('p',{staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('exchange_modal_point5_heading')))]),_vm._v(_vm._s(_vm.$t('exchange_modal_point5_text')))]),_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.accept_conditions),callback:function ($$v) {_vm.accept_conditions=$$v},expression:"accept_conditions"}},[_vm._v(_vm._s(_vm.$t('accept')))]),(_vm.acceptConditionsErrorMessage)?_c('p',{staticClass:"mb-2",staticStyle:{"color":"red","font-size":"11px"}},[_vm._v(_vm._s(_vm.acceptConditionsErrorMessage))]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.ExchangeAmount}},[_vm._v("Change")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }